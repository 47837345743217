import React from 'react';
import wine_glass from './wine_glass.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={wine_glass} className="App-logo" alt="logo" />
        <p>
          Reload your drink as much as you need! 
        </p>
        <p>
          - 쉬라를 먹으며 쉬는 중인 이술경 선생
        </p>
      </header>
    </div>
  );
}

export default App;
