import React from "react";

function Header() {
  return (
    <div>
        <h1 className='mainTitle' href="/">Code, Drink and Love.</h1>
        <h4>Find your favorite thing!</h4>
        <ul className="header">
        </ul>
    </div>
  );
}
export default Header;