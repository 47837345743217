import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Header from './header.js';
import Footer from './footer.js';

const routing = (
        <div>
            <Header />
            <hr className='new1' />
            <App />
            <Footer />
        </div>
);

ReactDOM.render(routing, document.getElementById('root'));


serviceWorker.unregister();
