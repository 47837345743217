import React from "react";

function Footer() {
  return (
    <div className='footer'>
      <p>Copyright &copy; 2020 heejour All rights reserved</p>
      <p>Contact : <a href="mailto:amy.winehouse@kakao.com"> amy.winehouse@kakao.com</a>.</p>
    </div>
  );
}

export default Footer;